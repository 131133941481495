import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import TXRoom from '../../../images/shared/TXRoom.webp';
import { Link } from 'gatsby';

export function SchemaRating({ globalData }) {
  return (
    <section
      id='schema-rating'
      className='divider-section w-100 py-4 milan-bg-navy'
    >
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-11'>
            <div
              itemScope
              itemType='schema.org/AggregateRating'
              className='text-center milan-text-white'
            >
              <h2 className=''>
                <Link to='/locations/reviews/' className='milan-text-white'>
                  See Milan <span itemProp='name'>Laser Hair Removal</span>{' '}
                  Reviews
                </Link>
              </h2>
              <p className='mb-0 milan-fp'>
                <span itemProp='ratingValue'> {globalData?.avg_rating}</span>{' '}
                stars - based on
                <span itemProp='reviewCount'>
                  {' '}
                  {globalData?.reviews_count}
                </span>{' '}
                <Link to='/locations/reviews/' className=''>
                  reviews
                </Link>
              </p>
              <div
                itemProp='itemReviewed'
                itemScope
                itemType='schema.org/Product'
              >
                <div
                  itemProp='aggregateRating'
                  className='milan-fp'
                  itemScope
                  itemType='https://schema.org/AggregateRating'
                >
                  Rated{' '}
                  <span itemProp='ratingValue'>{globalData?.avg_rating}</span>/5
                  based on{' '}
                  <span itemProp='reviewCount'>
                    {globalData?.reviews_count}
                  </span>{' '}
                  reviews
                </div>
                <br />
                <img
                  className='d-none'
                  itemProp='image'
                  src={TXRoom}
                  alt='Laser Hair Removal'
                />
                <Link to='/locations/reviews/'>
                  <StaticImage
                    src='../../../images/shared/Divider-5star.png'
                    style={{ maxWidth: '250px' }}
                    alt='Milan Laser Rate'
                  />
                </Link>{' '}
                <br />
                <hr className='mt-4' />
                <div className='lh-sm'>
                  <small itemProp='name'>Laser Hair Removal</small> |
                  <small itemProp='brand'> Milan Laser</small>
                  <small itemProp='description'>
                    {' '}
                    is one of the nation’s leading laser hair removal companies.
                    We are committed to providing our clients with world-class
                    customer service and permanent results. Our physicians and
                    highly-trained medical staff utilize ongoing medical
                    research to ensure that all treatments are performed safely
                    and are tailored to give every client the best results
                    possible. Our goal is to provide 100% customer satisfaction
                    to every client and we will do everything we can to ensure
                    our clients are happy with their experience as well as their
                    results.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default SchemaRating;
