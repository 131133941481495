import React from 'react';
import { Link } from 'gatsby';
import { PLANS_START_AT } from '../../utils';

export function LPCredit({ globalData }) {
    const planAmount = globalData?.plans_start_at ?? PLANS_START_AT;
    return (
        <section id="lp-credit" className="milan-bg-tur milan-credit-bg">
            <div className="background spacer d-lg-none hr-divider hr-divider-reversed-flipped hr-divider-turq-light"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-xxl-6 vrt-divider vrt-divider-turq-alt"></div>
                    <div className="col-lg-7 col-xxl-6 milan-bg-tur milan-text-white milan-py">
                        <div className="row">
                            <div className="col">
                                <h2 className="subheadlg">100% Guaranteed Credit Approval!</h2>
                                <p>
                                    At Milan, we believe everyone deserves to get smooth, hair-free skin at a price they
                                    can afford. That’s why we offer everyone affordable payment plans!
                                </p>
                                <ul className="list-style-disc text-start lh-lg">
                                    <li>
                                        <strong>100%</strong> Credit Approval.
                                    </li>
                                    <li>
                                        Payments as low as <strong>{planAmount}</strong>
                                        /month.
                                    </li>
                                    <li>
                                        The{' '}
                                        <Link to="/specials/#guarantee" className="milan-primary-text">
                                            Unlimited Package
                                        </Link>
                                        , with a lifetime guarantee.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row gy-3 gx-lg-2 gx-xl-4 pt-5">
                            <div className="col-12 col-md-auto text-center">
                                <Link className="milan-btn milan-navy-btn" to="/specials/">
                                    Current Specials
                                </Link>
                            </div>
                            <div className="col-12 col-md-auto text-center">
                                <Link className="milan-btn milan-navy-btn" to="/process/">
                                    Lasers & Technology
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default LPCredit;
